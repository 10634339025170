@use "sass:math";

[class*='FilterModal'] {
  padding: 0;
  z-index: 999911;

  .Modal-Window {
    max-width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.FilterModal {
  padding-top: 104px;
  background-color: var(--color-blue-primary);
  color: #fff;
  // height: 100%;
  padding-bottom: var(--space-3xxl);
  min-height: 100%;

  @media screen and (max-width: 767px) {
    padding-top: 100px;
  }

  &-TypesAndDate {
    display: flex;
    justify-content: space-between;
    margin: 38px -8px;
    color: var(--color-reach-black);

    @media screen and (max-width: 767px) {
      flex-flow: column;
    }

    > * {
      flex: 1;
      margin: 8px;
    }
  }

  &-Category {
    margin-top: var(--space-3xl);

    &-Title {
      margin-bottom: var(--space-xl);
    }

    &-Industries {
      .CheckboxGroup-Item {
        width: 50%;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }

    &-Tags {
      .CheckboxGroup-Item {
        width: 25%;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }

    .Radio-Input {
      border-color: #fff !important;

      &:checked:before {
        background-color: #fff !important;
      }
    }
  }

  &-Controls {
    margin-bottom: var(--space-6xl);

    &-Button {
      text-align: right;

      .Button_active {
        overflow: visible;

        &:before {
          content: '';
          position: absolute;
          top: -4px;
          right: -4px;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: var(--color-fire-opal);
        }
      }
    }

    &-Selected {
      margin-top: var(--space-6xl);

      &-Title {
        margin-bottom: var(--space-m);
      }

      &-List {
        display: flex;
        flex-flow: wrap;
        margin: calc(var(--space-2xs) * -1);

        > div {
          margin: var(--space-2xs);
        }
      }
    }
  }

  &-Bottom {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color-blue-primary-dark);
    box-shadow: 0 -1px 4px 0 rgba(12, 16, 28, 0.1),
      0 -2px 10px 2px rgba(8, 16, 36, 0.24);
    padding: var(--space-xl) var(--space-3xl);
    display: flex;
    align-items: center;
    margin: 0 -10px;

    @media screen and (max-width: 767px) {
      padding: var(--space-m);
    }

    > * {
      margin: 0 10px;
    }

    .Button_view_secondary {
      width: percentage(math.div(566, 688));
      justify-content: center;

      @media screen and (max-width: 767px) {
        width: percentage(math.div(178, 288));
      }
    }

    .Button_view_transparent {
      width: percentage(math.div(100, 688));
      justify-content: center;

      @media screen and (max-width: 767px) {
        width: percentage(math.div(100, 288));
      }
    }
  }
}
