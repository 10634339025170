@mixin breakpoint {
  &topGap {
    &_0 {
      --block-space-top: 0;
    }

    &_4xs {
      --block-space-top: var(--space-4xs);
    }

    &_3xs {
      --block-space-top: var(--space-3xs);
    }

    &_2xs {
      --block-space-top: var(--space-2xs);
    }

    &_xs {
      --block-space-top: var(--space-xs);
    }

    &_2s {
      --block-space-top: var(--space-2s);
    }

    &_s {
      --block-space-top: var(--space-s);
    }

    &_m {
      --block-space-top: var(--space-m);
    }

    &_2m {
      --block-space-top: var(--space-2m);
    }

    &_l {
      --block-space-top: var(--space-l);
    }

    &_xl {
      --block-space-top: var(--space-xl);
    }

    &_2xl {
      --block-space-top: var(--space-2xl);
    }

    &_3xl {
      --block-space-top: var(--space-3xl);
    }

    &_4xl {
      --block-space-top: var(--space-4xl);
    }

    &_5xl {
      --block-space-top: var(--space-5xl);
    }

    &_6xl {
      --block-space-top: var(--space-6xl);
    }

    &_7xl {
      --block-space-top: var(--space-7xl);
    }

    &_xxl {
      --block-space-top: var(--space-xxl);
    }

    &_2xxl {
      --block-space-top: var(--space-2xxl);
    }

    &_3xxl {
      --block-space-top: var(--space-3xxl);
    }

    &_4xxl {
      --block-space-top: var(--space-4xxl);
    }

    &_5xxl {
      --block-space-top: var(--space-5xxl);
    }

    &_6xxl {
      --block-space-top: var(--space-6xxl);
    }

    &_7xxl {
      --block-space-top: var(--space-7xxl);
    }

    &_8xxl {
      --block-space-top: var(--space-8xxl);
    }
  }

  &bottomGap {
    &_0 {
      --block-space-bottom: 0;
    }

    &_4xs {
      --block-space-bottom: var(--space-4xs);
    }

    &_3xs {
      --block-space-bottom: var(--space-3xs);
    }

    &_2xs {
      --block-space-bottom: var(--space-2xs);
    }

    &_xs {
      --block-space-bottom: var(--space-xs);
    }

    &_2s {
      --block-space-bottom: var(--space-2s);
    }

    &_s {
      --block-space-bottom: var(--space-s);
    }

    &_m {
      --block-space-bottom: var(--space-m);
    }

    &_2m {
      --block-space-bottom: var(--space-2m);
    }

    &_l {
      --block-space-bottom: var(--space-l);
    }

    &_xl {
      --block-space-bottom: var(--space-xl);
    }

    &_2xl {
      --block-space-bottom: var(--space-2xl);
    }

    &_3xl {
      --block-space-bottom: var(--space-3xl);
    }

    &_4xl {
      --block-space-bottom: var(--space-4xl);
    }

    &_5xl {
      --block-space-bottom: var(--space-5xl);
    }

    &_6xl {
      --block-space-bottom: var(--space-6xl);
    }

    &_7xl {
      --block-space-bottom: var(--space-7xl);
    }

    &_xxl {
      --block-space-bottom: var(--space-xxl);
    }

    &_2xxl {
      --block-space-bottom: var(--space-2xxl);
    }

    &_3xxl {
      --block-space-bottom: var(--space-3xxl);
    }

    &_4xxl {
      --block-space-bottom: var(--space-4xxl);
    }

    &_5xxl {
      --block-space-bottom: var(--space-5xxl);
    }

    &_6xxl {
      --block-space-bottom: var(--space-6xxl);
    }

    &_7xxl {
      --block-space-bottom: var(--space-7xxl);
    }

    &_8xxl {
      --block-space-bottom: var(--space-8xxl);
    }
  }

  &sideGap {
    &_0 {
      --block-space-x: 0;
    }

    &_4xs {
      --block-space-x: var(--space-4xs);
    }

    &_3xs {
      --block-space-x: var(--space-3xs);
    }

    &_2xs {
      --block-space-x: var(--space-2xs);
    }

    &_xs {
      --block-space-x: var(--space-xs);
    }

    &_2s {
      --block-space-x: var(--space-2s);
    }

    &_s {
      --block-space-x: var(--space-s);
    }

    &_m {
      --block-space-x: var(--space-m);
    }

    &_2m {
      --block-space-x: var(--space-2m);
    }

    &_l {
      --block-space-x: var(--space-l);
    }

    &_xl {
      --block-space-x: var(--space-xl);
    }

    &_2xl {
      --block-space-x: var(--space-2xl);
    }

    &_3xl {
      --block-space-x: var(--space-3xl);
    }

    &_4xl {
      --block-space-x: var(--space-4xl);
    }

    &_5xl {
      --block-space-x: var(--space-5xl);
    }

    &_6xl {
      --block-space-x: var(--space-6xl);
    }

    &_7xl {
      --block-space-x: var(--space-7xl);
    }

    &_xxl {
      --block-space-x: var(--space-xxl);
    }

    &_2xxl {
      --block-space-x: var(--space-2xxl);
    }

    &_3xxl {
      --block-space-x: var(--space-3xxl);
    }

    &_4xxl {
      --block-space-x: var(--space-4xxl);
    }

    &_5xxl {
      --block-space-x: var(--space-5xxl);
    }
  }
}

.SiteBlock {
  --block-space-x: 0;
  --block-space-top: 0;
  --block-space-bottom: 0;
  position: relative;
  padding-top: var(--block-space-top);
  padding-right: var(--block-space-x);
  padding-left: var(--block-space-x);
  padding-bottom: var(--block-space-bottom);

  &_useMargin {
    margin-top: var(--block-space-top);
    margin-bottom: var(--block-space-bottom);
    padding-top: 0;
    padding-bottom: 0;
  }

  &_view {
    &_primary {
      color: #fff;
      background-color: var(--color-blue-primary);
    }
  }

  &_ {
    @include breakpoint;
  }

  @media screen and (max-width: 767px) {
    &_mobile\: {
      @include breakpoint;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1199px) {
    &_tablet\: {
      @include breakpoint;
    }
  }

  @media screen and (min-width: 1200px) {
    &_desktop\: {
      @include breakpoint;
    }
  }
}
