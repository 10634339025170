.HeadingLinkCard {
  background-color: var(--color-platinum);
  padding: var(--space-2xl);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-xl);

  .Title {
    margin: 0 !important;
  }

  @media screen and (max-width: 767px) {
    padding: var(--space-xl);
  }
}
