.ClientCard {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 180px;
  padding: calc(var(--space-3xl) + 4px) var(--space-xl);
  flex-flow: wrap;

  @media screen and (min-width: 768px) and (max-width: 1199px) {
    padding: calc(var(--space-3xl) + 4px) var(--space-4xl);
  }

  @media screen and (max-width: 767px) {
    padding: calc(var(--space-xl) - 2px) calc(var(--space-s) - 1px);
  }

  &:not(.ClientCard_isPageHidden):hover {
    @media screen and (min-width: 1199px) {
      .ClientCard {
        &-Inner {
          opacity: 1;
        }
      }
    }
  }

  &-Inner {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    padding: var(--space-2xl);
    background: #fff;
    opacity: 0;
    box-shadow: 0 14px 30px 0 rgba(157, 167, 170, 0.08),
      0 4px 8px 0 rgba(157, 167, 170, 0.16);
    transition: 0.1s all ease;
    flex-flow: column;

    @media screen and (max-width: 767px) {
      padding: var(--space-m);
    }
  }

  &-OverlayLink {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &-Button {
    margin-top: auto;
    padding-top: var(--space-s);
  }
}
