@use "sass:math";

.Banner {
  --banner-border-radius: 16px;
  position: relative;
  padding: var(--banner-spacing-vertical) var(--banner-spacing-horizontal);
  background-color: var(--banner-bg);
  border-radius: var(--banner-border-radius);
  overflow: hidden;

  &_view {
    &_glassdoor {
      --banner-spacing-vertical: var(--space-2xl);
      --banner-spacing-horizontal: var(--space-2xl);
      --banner-bg: var(--color-turquise);
      display: flex;
      min-height: 464px;

      @media screen and (min-width: 768px) and (max-width: 1199px) {
        min-height: 208px;
      }

      @media screen and (max-width: 767px) {
        --banner-spacing-horizontal: var(--space-xl);
        min-height: 280px;
        padding-bottom: var(--space-xl);
      }

      &::before {
        content: '';
        position: absolute;
        right: var(--space-xl);
        bottom: 0;
        width: 122px;
        height: 122px;
        background-image: url('./glassdoor_banner_decor.png');
        background-position: center center;
        background-size: cover;

        @media screen and (min-width: 768px) and (max-width: 1199px) {
          right: var(--space-2xl);
        }
        @media screen and (max-width: 767px) {
          right: var(--space-l);
          width: 88px;
          height: 88px;
        }
      }

      .Banner-Title {
        margin-bottom: auto;
      }
    }

    &_vacancy {
      --banner-spacing-vertical: var(--space-3xl);
      --banner-spacing-horizontal: var(--space-2xl);
      --banner-bg: var(--color-orange);
      overflow: hidden;
      min-height: 528px;

      @media screen and (min-width: 768px) and (max-width: 1199px) {
        min-height: 272px;
        padding-bottom: var(--space-m);
      }

      @media screen and (max-width: 767px) {
        --banner-spacing-vertical: var(--space-2xl);
        --banner-spacing-horizontal: var(--space-xl);
        min-height: 464px;
      }

      &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: -85px;
        width: 301px;
        height: 321px;
        background-image: url('./vacancy_banner_decor.svg');
        background-position: center;
        background-size: cover;

        @media screen and (min-width: 768px) and (max-width: 1199px) {
          top: 0;
          bottom: auto;
        }

        @media screen and (max-width: 767px) {
          top: auto;
          right: 0;
          bottom: 0;
          width: 191px;
          height: 184px;
        }
      }

      .Banner-Inner {
        @media screen and (min-width: 768px) and (max-width: 1199px) {
          max-width: 328px;
        }
      }

      .Banner-Title {
        margin-bottom: var(--space-m);
      }

      .Banner-Text {
        margin-bottom: var(--space-3xl);

        @media screen and (max-width: 767px) {
          margin-bottom: var(--space-xl);
        }
      }

      .Banner-SecondaryButton {
        margin-top: var(--space-xs);
      }
    }

    &_vacancy-row {
      --banner-spacing-vertical: var(--space-3xl);
      --banner-spacing-horizontal: var(--space-3xl);
      --banner-bg: var(--color-orange);
      overflow: hidden;

      @media screen and (min-width: 768px) and (max-width: 1199px) {
        min-height: 272px;
        padding-bottom: var(--space-m);
      }

      @media screen and (max-width: 767px) {
        --banner-spacing-vertical: var(--space-2xl);
        --banner-spacing-horizontal: var(--space-xl);
        min-height: 464px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: auto;
        width: 301px;
        height: 321px;
        background-image: url('./vacancy_banner_decor.svg');
        background-position: center;
        background-size: cover;

        @media screen and (min-width: 768px) and (max-width: 1199px) {
          top: 0;
          bottom: auto;
        }

        @media screen and (max-width: 767px) {
          top: auto;
          right: 0;
          bottom: 0;
          width: 191px;
          height: 184px;
        }
      }

      .Banner-Inner {
        max-width: 50%;

        @media screen and (min-width: 768px) and (max-width: 1199px) {
          max-width: 328px;
        }

        @media screen and (max-width: 767px) {
          max-width: 100%;
        }
      }

      .Banner-Title {
        margin-bottom: var(--space-m);
      }

      .Banner-Text {
        margin-bottom: var(--space-3xl);

        @media screen and (max-width: 767px) {
          margin-bottom: var(--space-xl);
        }
      }

      .Banner-SecondaryButton {
        margin-top: var(--space-xs);
      }
    }

    &_section {
      display: flex;
      justify-content: space-between;

      .Banner-Inner {
        padding: var(--space-2xl);

        @media screen and (max-width: 1199px) {
          padding: var(--space-xl);
        }
      }

      .Banner-Title {
        margin-bottom: var(--space-xs);
      }

      .Banner-Button {
        margin-top: auto;
        padding-top: var(--space-l);

        @media screen and (max-width: 1199px) {
          padding-top: var(--space-2xl);
        }
      }
    }

    &_landing {
      overflow: hidden;

      .Banner-Inner {
        width: percentage(math.div(520, 808));
        padding: var(--space-xl);
        background-color: #032c66;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      .Banner-Picture {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: percentage(math.div(288, 808));

        .Picture,
        .Picture picture,
        .Picture img {
          border-radius: 0;
        }

        @media screen and (max-width: 1199px) {
          .Picture,
          .Picture picture,
          .Picture img {
            height: 100%;
            object-fit: cover;
          }
        }

        @media screen and (max-width: 767px) {
          position: relative;
          width: 100%;
        }
      }

      .Banner-Button {
        margin-top: var(--space-6xl);
      }
    }

    &_landing-column {
      overflow: hidden;
      background-color: #032c66;

      .Banner-Inner {
        width: 100%;
        padding: var(--space-xl);
      }

      .Banner-Button {
        margin-top: var(--space-5xl);
      }

      .Banner-Picture {
        margin-top: var(--space-2xl);
      }
    }

    &_case {
      display: flex;
      flex-flow: wrap;

      .Banner-Inner {
        padding: var(--space-3xl) var(--space-3xl) var(--space-6xl);

        @media screen and (max-width: 767px) {
          width: 100%;
          padding: var(--space-xl) var(--space-xl) var(--space-3xl);
        }

        &_needPicture {
          @media (min-width: 1200px) {
            width: percentage(math.div(704, 1224));
          }

          @media screen and (min-width: 768px) {
            width: percentage(math.div(424, 688));
          }
        }
      }

      .Banner-InnerContent {
        @media screen and (min-width: 1200px) {
          display: flex;
          justify-content: space-between;
        }

        & + .Banner-Button {
          margin-top: var(--space-xl);
        }
      }

      .Banner-Picture {
        display: flex;
        justify-content: center;
        align-items: center;
        width: percentage(math.div(416, 1224));
        margin-left: auto;
        padding: var(--space-2xl);

        @media screen and (max-width: 1199px) {
          width: percentage(math.div(216, 688));
        }

        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 0 var(--space-xl) var(--space-xl);
        }
      }

      .Banner-Title {
        margin-bottom: var(--space-xl);
      }

      .Banner-List {
        margin-top: var(--space-xl);
      }

      .Banner-Inner__needPicture .Banner-Text,
      .Banner-List {
        @media screen and (min-width: 1200px) {
          flex: 0 0 calc(50% - 16px);
          margin-top: 0;
        }
      }

      .Banner-Text {
        @media screen and (min-width: 1200px) {
          padding-right: var(--space-xs);
        }
      }

      .Banner-List {
        @media screen and (min-width: 1200px) {
          padding-left: var(--space-xs);
        }
      }
    }
  }

  &_inverted {
    color: #fff;
  }

  &-Link {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &-SubTitle {
    margin-bottom: var(--space-m);
  }

  &-Inner {
    display: flex;
    flex-flow: column;
  }

  &-Picture {
    position: relative;

    .Picture {
      border-radius: 0;
    }
  }

  &-Picture-Label {
    position: absolute;
    top: var(--space-m);
    right: var(--space-xl);
    background-color: var(--color-orange);
    transform: rotate(5deg);
    z-index: 2;

    @media (min-width: 768px) {
      top: var(--space-2xl);
      right: var(--space-2xl);
    }

    @media (min-width: 1200px) {
      top: var(--space-3xl);
      right: var(--space-3xl);
    }
  }

  /* styles from FileCard.css */

  &-Modal {
    &-Title {
      margin-bottom: var(--space-m);
    }

    &-Form {
      margin-bottom: var(--space-2xl);
    }

    &-Subtitle {
      display: block;
      margin-bottom: var(--space-xs);
    }
  }

  &-Longer-Popup {
    width: 100%;

    @media (min-width: 768px) {
      width: 600px;
    }

    @media (min-width: 1200px) {
      display: flex;
      width: 1016px;

      .FileCard-Left-Side {
        display: flex;
        flex-direction: column;
        width: 352px;
        margin-right: var(--space-xxl);

        &-Top {
          flex-grow: 1;
        }
      }

      .FileCardForm {
        flex: 1 0 auto;
        margin-top: 0;
      }
    }

    .FileCard-Left-Side {
      &-Picture {
        margin-top: var(--space-xl);
      }
    }
  }

  &-ModalSuccess {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: var(--space-3xl);
    color: #fff;
    background-color: var(--color-blue-primary);
    border-radius: 16px;

    &::after {
      content: '';
      display: block;
      width: 89px;
      height: 87px;
      margin-top: 109px;
      margin-left: auto;
      /* background-image: url('./img/form_lil.svg'); */
    }

    .GetInTouchForm-Title {
      max-width: 300px;
    }
  }
}
