#root .Theme .page-inner {
  &--banner-show {
    padding-top: 176px;

    @media screen and (max-width: 1199px) {
      padding-top: 154px;
    }

    @media screen and (max-width: 759px) {
      padding-top: 144px;
    }

    @media screen and (max-width: 319px) {
      padding-top: 168px;
    }
  }

  &--banner-hidden {
    padding-top: 64px;

    @media (min-width: 768px) {
      padding-top: 72px;
    }

    @media (min-width: 1200px) {
      padding-top: 96px;
    }
  }
}
