.MobileMenu {
  z-index: 999911;
  padding: 0;

  @media (min-width: 1200px) {
    display: none !important;
  }

  .Modal-Overlay {
    opacity: 0.1;
  }

  .Modal-Window {
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 288px;
    background-color: #fff;
    box-shadow: none;

    @media screen and (max-width: 767px) {
      min-width: 100vw;
    }
  }
}

.HeaderMenuMobile {
  padding-top: 64px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding-top: 72px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &-Main {
    overflow: scroll;
    flex-grow: 1;
  }

  &-List {
    flex-grow: 1;
  }

  &-Menu-Item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--color-decor);

    &:first-child {
      .HeaderMenuMobile-Link_primary {
        border-top: 1px solid var(--color-decor);
      }
    }
  }

  &-Sub-List {
    display: none;
    flex-direction: column;

    &_visible {
      display: flex;
    }
  }

  &-Link {
    height: 48px;

    .Button-Label {
      line-height: 48px;
      text-align: left;
      flex-grow: 1;
    }

    &_open {
      position: fixed !important;
      top: 64px;
      width: 100%;
      right: 0;
      z-index: 1;

      @media (min-width: 768px) {
        top: 72px;
        width: 400px;
      }

      & + .HeaderMenuMobile-Sub-List {
        margin-top: 48px;
      }
    }

    &_hasChildren {
      position: relative;
      padding-right: 64px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 48px;
        width: 1px;
        background: var(--color-decor);
      }

      .IconArrowDown {
        position: absolute;
        top: 12px;
        right: 12px;
        margin-left: 0;
        transition: 0.2s ease-in;
      }
    }

    &_active {
      .IconArrowDown {
        transform: rotate(180deg);
      }
    }

    &_primary,
    &_secondary,
    &_tertiary {
      background: #fff;

      &:hover {
        background-color: #e6eaf2;
      }
    }

    &_secondary {
      padding-left: 40px;
    }

    &_tertiary {
      padding-left: 80px;
      font-weight: 400;
    }

    &_secondary,
    &_tertiary {
      font-size: 13px;
      letter-spacing: normal;
      text-transform: none;
      background-color: var(--color-cultured);
    }

    &_all {
      font-weight: 600;
      text-transform: none;
      letter-spacing: normal;
      font-size: 13px;
      padding-left: 40px;
      color: var(--color-web);
    }

    &_isImage {
      padding-left: 40px;

      .Picture {
        &,
        picture {
          display: flex;
        }

        img {
          height: 16px;
          width: auto;
        }
      }
    }

    &_withLogo {
      justify-content: flex-start;

      .Picture {
        margin-right: var(--space-m);

        &,
        picture {
          display: flex;
        }

        img {
          height: 24px;
          width: auto;
        }
      }

      .Button-Label {
        flex-grow: 0;
      }

      .IconOpenInNew {
        width: 16px;
        height: 16px;
        margin-left: 8px !important;
      }
    }
  }

  &-Cta {
    padding: var(--space-m);
    display: flex;
    align-items: flex-start;

    .Button {
      width: auto;
      min-width: 164px;
    }
  }

  &-Languages {
    border-top: 1px solid var(--color-decor);
  }

  &-Languages-Item {
    display: inline-flex;
    padding: var(--space-m);
    text-decoration: none;
    opacity: 0.5;

    &_Active {
      opacity: 1;
    }
  }
}
