.EventCard {
  position: relative;
  overflow: hidden;

  &_size {
    &_s {
      .EventCard-Picture {
        &::before {
          content: none;
        }
      }
    }

    &_m {
      min-height: 424px;
      padding: var(--space-xl);
      background-color: #fff;
      border: 1px solid var(--color-platinum);
      transition: all 0.3s ease-in-out;

      @media screen and (max-width: 767px) {
        min-height: 400px;
      }

      &:not(.EventCard_noHover):hover {
        .EventCard-Title {
          color: var(--color-blue-primary-light);
        }
      }

      &:not(.EventCard_noHover):active {
        .EventCard-Title {
          color: var(--color-blue-primary-dark);
        }
      }

      .EventCard-Tags {
        margin-top: var(--space-m);
      }
    }

    &_l {
      padding: var(--space-3xl);

      .EventCard-Picture {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        .Picture {
          width: 100%;
          height: 100%;
        }

        picture,
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .EventCard-Title {
        max-width: 768px;
      }

      .EventCard-Tags {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  &_invertedStyle {
    color: #fff;
  }

  &-Link {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &-Inner {
    position: relative;
    z-index: 2;
    display: flex;
    height: 100%;
    flex-flow: column;
  }

  &-Picture {
    position: relative;
    user-select: none;

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: #000;
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &-Date {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-m);

    > div:not(:first-child) {
      margin-left: 4px;
    }
  }

  &-Title {
    transition: all 0.3s ease-in-out;
  }

  &-SubTitle {
    margin: var(--space-m) 0 0;
  }

  &-Tags {
    position: relative;
    z-index: 3;
  }

  &-Speakers {
    display: flex;
    margin-top: var(--space-2xl);

    > *:not(:last-child) {
      position: relative;
      margin-right: -8px;
    }
  }

  &-Bottom {
    margin-top: auto;
    padding-top: var(--space-6xl);
  }

  &-VideoCircle {
    pointer-events: none;
  }
}
