@use "sass:math";

.Header {
  position: relative;
  z-index: 9999111;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: background-color 0.3s;

  .container {
    display: flex;
    align-items: center;
    height: var(--header-height);

    @media screen and (max-width: 1199px) {
      --header-height: 72px;
    }

    @media screen and (max-width: 767px) {
      --header-height: 64px;
    }
  }

  &-LeftSide,
  &-RightSide {
    display: flex;
    align-items: center;
  }

  &-RightSide {
    flex: 1 1 auto;
    justify-content: flex-start;
    height: var(--header-height);
    margin-left: 64px;

    @media screen and (max-width: 1199px) {
      flex: none;
      margin-left: auto;
    }
  }

  &-Push {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &-Contact {
    margin-right: var(--space-4xl);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--shine-effect);
      animation: shine 5s ease-in-out infinite;
    }

    &.visible {
      opacity: 1;
      transition: 1.5s linear;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
      transition: 0.5s linear;
    }
  }

  &,
  &_modifier_scrolled,
  &_modifier_hovered,
  &_modifier_mobile-menu-opened,
  &_search-opened {
    background-color: #fff;

    .HeaderLogo {
      svg {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: block;
        }
      }
    }
  }

  &_modifier_transparent,
  &_modifier_transparentInverted {
    background-color: transparent;
  }

  &_modifier_scrolled {
    box-shadow: 0 14px 30px 0 rgba(157, 167, 170, 0.08),
      0 4px 8px 0 rgba(157, 167, 170, 0.16);
  }

  &_modifier_transparentInverted {
    .HeaderLogo {
      svg {
        &:last-child {
          display: none;
        }

        &:first-child {
          display: block;
        }
      }
    }

    .Button-Label,
    .Select-ControlValueContainer {
      color: #fff;
    }

    .Header-Contact {
      .Button-Label {
        color: var(--color-reach-black);
      }
    }

    .Header-Languages {
      .Select {
        .Icon-Svg {
          path {
            fill: #fff;
          }
        }
      }

      .Select-Control[aria-expanded='true'] {
        svg {
          path {
            fill: var(--color-rich-black);
          }
        }
      }
    }

    .Header-Burger {
      svg {
        path {
          fill: #fff;
        }
      }
    }

    .HeaderWrapper-Search {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}

// btn animation

:root {
  --shine-degree: 120deg;
  --shine-color: rgba(255, 255, 255, 0.2);
  --shine-effect: linear-gradient(
    var(--shine-degree),
    transparent,
    var(--shine-color),
    transparent
  );
  --shine-transition: all 0.65s ease-in-out;
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  #{(math.div(0.65, 5 + 0.65) * 100%)},
  100% {
    left: 100%;
    transition-property: left;
  }
}
