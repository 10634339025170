@use "sass:math";

.MainForm {
  padding-top: 160px;
  padding-bottom: 130px;
  background-color: var(--color-decor);
  position: relative;

  @media screen and (max-width: 1199px) {
    padding-top: 116px;
    padding-bottom: 112px;
  }

  @media screen and (max-width: 767px) {
    padding-top: var(--space-xxl);
    padding-bottom: 216px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: var(--space-6xl);
    width: 482px;
    height: 371px;
    background-image: url('/assets/img/main_form_decor.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: none;

    @media screen and (max-width: 767px) {
      width: 280px;
      height: 214px;
      bottom: 34px;
      left: 0;
      right: auto;
      top: auto;
      background-image: url('/assets/img/main_form_decor_mobile.svg');
    }
  }

  .Grid {
    position: relative;
    z-index: 2;
  }

  &-Title {
    margin-bottom: var(--space-3xl);

    @media screen and (max-width: 767px) {
      margin-bottom: var(--space-l);
    }
  }

  &-SubTitle {
    @media screen and (max-width: 767px) {
      max-width: 152px;
      margin-bottom: var(--space-5xl);
    }
  }

  &-Inner {
    display: flex;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 1199px) {
      max-width: 584px;
    }

    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  &-Body {
    width: percentage(math.div(536, 600));
    position: relative;

    @media screen and (max-width: 1199px) {
      width: percentage(math.div(520, 584));
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .Checkbox {
      margin-top: var(--space-3xl);
    }

    .Pagination {
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
    }
  }

  &-Button {
    margin-top: calc(var(--space-xl) + 3px);
    margin-left: var(--space-xs);

    @media screen and (max-width: 767px) {
      margin-top: var(--space-m);
      text-align: right;
      margin-left: 0;
    }
  }

  &-Right {
    padding-left: var(--space-6xl);

    @media screen and (max-width: 1199px) {
      padding-left: 0;
    }

    > .Text {
      max-width: 220px;
    }
  }
}
