.HotVacancies {
  &-Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .swiper-pagination {
      position: relative;
      margin-top: 7px;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &-Title {
    display: flex;
    align-items: flex-end;

    .Icon {
      margin-right: var(--space-2xs);
    }
  }

  &-Slider {
  }

  .swiper {
    overflow: hidden;
    padding: 30px;
    margin: -30px;

    .swiper-slide {
      margin-top: 0 !important;
    }
  }

  &-Bottom {
    margin-top: 10px;
    display: flex;
    align-items: center;
    min-height: 40px;
    position: relative;
    z-index: 5;

    @media screen and (max-width: 767px) {
      margin-top: 12px;
    }
  }

  &-Button {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &-Nav {
    display: flex;
    margin-left: auto;
    margin-top: 0;

    @media screen and (max-width: 767px) {
      margin-right: auto;
    }

    .swiper-button-prev,
    .swiper-button-next {
      position: relative;
      margin-top: 0;
      margin-left: 0;
      // display: block !important;
    }

    .swiper-button-next {
      margin-left: 35px;

      @media screen and (max-width: 767px) {
        margin-left: 100px;
      }
    }
  }
}
