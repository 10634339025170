.TestimonialCard {
  display: flex;
  padding: var(--space-xl);
  background-color: #fff;
  flex-flow: column;

  &_view {
    &_ghost {
      position: relative;
      padding: 0;
      padding-top: 0;
      padding-left: 72px;
      background-color: transparent;
      box-shadow: none;

      .TestimonialCard-Title {
        position: static;
        padding-top: 0;
      }
    }
  }

  &_size {
    &_s {
      .TestimonialCard-User {
        padding-top: var(--space-m);
      }
    }
  }

  &-Title {
    position: relative;
    padding-top: var(--space-2xl);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 16px;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='16px' viewBox='0 0 20 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='1.-All-Pages' stroke='none' stroke-width='1' fill='none'%3E%3Cg id='COM/Travel_1300' transform='translate(-72.000000, -4448.000000)' fill='%23284086'%3E%3Cg id='Quotes' transform='translate(40.000000, 4320.000000)'%3E%3Cg id='content' transform='translate(0.000000, 56.000000)'%3E%3Cg id='Components/Cards/Highlights/Quote' transform='translate(0.000000, 40.000000)'%3E%3Cpath d='M40,32 L40,48 L32,40 L32,32 L40,32 Z M44,32 L52,32 L52,48 L44,40 L44,32 Z' id='quotation-marks-copy'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  &-User {
    margin-top: auto;
    padding-top: var(--space-2xl);
  }

  &-Button {
    margin-top: var(--space-m);
  }
}
