.FileCardForm {
  min-width: 520px;
  margin-top: var(--space-2xl);

  @media screen and (max-width: 1199px) {
    min-width: 100%;
  }

  &:not(&_type) {
    .FileCardForm-Bottom {
      margin-top: var(--space-xs);
    }
  }

  &-Bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .Button {
      margin-left: var(--space-xl);
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;

      .Button {
        margin-left: 0;
        margin-top: var(--space-m);
      }
    }
  }
}
