.Article {
  .photo-blockquote {
    display: flex;
    flex-direction: column;
    margin: 32px 0;
    padding: 0 20px;
    background-color: #fff;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      margin: 40px 0;
      padding: 0;
    }

    img {
      max-width: 128px;
      height: auto;
      margin-bottom: 0;
      flex-shrink: 0;

      @media (min-width: 768px) {
        max-width: 100%;
      }
    }

    .text {
      padding: 30px 0 45px;
      font-size: 20px;
      line-height: 1.6;

      @media (min-width: 768px) {
        padding: 25px;
      }

      @media (min-width: 768px) {
        padding: 45px 40px;
        font-size: 22px;
      }
    }
  }

  .link-icon-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 32px 0;
    padding: 20px;
    background-color: #fff;
    border: none !important;
    text-decoration: none;

    @media (min-width: 768px) {
      margin: 40px 0;
    }

    @media (min-width: 1200px) {
      padding: 30px 50px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 3px;
      background-color: #1e3867;
    }

    &__top {
      display: flex;
      align-items: center;
      color: #f39200;
      color: #686868;
      font-size: 12px;
      font-weight: bold;
      line-height: normal;
      text-decoration: none;
      letter-spacing: 1.75px;
      text-transform: uppercase;
      transition: 0.2s ease-in;

      img,
      svg {
        margin: 0 12px 0 0 !important;
      }
    }

    &__heading {
      margin: 20px 0 0 0 !important;
      font-size: 20px;
      font-weight: bold;
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 22px !important;
      }

      @media (min-width: 1200px) {
        font-size: 24px;
      }
    }

    &__arrow {
      margin-top: 20px;

      .icon {
        position: relative;
        padding-left: 26px;
        transition: 0.2s ease-in;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 11px;
          width: 26px;
          height: 1px;
          margin-top: -1px;
          background-color: #f39200;
          transition: 0.2s ease-in;
          transform: translateY(-50%);
        }

        svg path {
          transition: 0.2s ease-in;
        }
      }
    }

    &:hover {
      .icon {
        padding-left: 40px;

        &::after {
          width: 40px;
        }

        svg path {
          stroke: #f39200;
        }
      }
    }

    &--nogap {
      margin: 0;
    }

    &--red {
      &::after {
        background-color: #d8422d;
      }
    }

    &--green {
      &::after {
        background-color: #04a87d;
      }
    }

    &--yellow {
      &::after {
        background-color: #f39200;
      }
    }
  }

  .p-important {
    margin: 32px 0;
    padding: 36px 20px;
    color: #fff;
    background-color: #72bfde;
    font-size: 20px;
    line-height: 1.6;

    @media (min-width: 768px) {
      margin: 40px 0;
    }

    @media (min-width: 1200px) {
      padding: 36px 50px;
      font-size: 22px;
    }
  }

  .blog-cta-in-content {
    display: flex;
    flex-direction: column;
    margin: 32px 0;
    padding: 20px;
    background-color: #404040;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 40px 0;
    }

    &__heading {
      margin: 0 !important;
      color: #fff;
      font-size: 24px !important;
      font-weight: bold !important;
      line-height: 1.25 !important;

      @media (min-width: 768px) {
        padding-right: 20px;
      }

      @media (min-width: 1200px) {
        font-size: 30px !important;
      }
    }

    &__btn {
      margin-top: 24px;
      padding: 12px 20px;
      color: #fff !important;
      background-color: #03a87d;
      border: none !important;
      border-radius: 2px;
      box-shadow: none;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.142;
      text-decoration: none;
      letter-spacing: 1.75px;
      text-transform: uppercase;
      cursor: pointer;
      transition: 0.2s ease-in;
      -webkit-appearance: none;

      &:hover {
        background-color: darken(#03a87d, 6);
      }

      @media (min-width: 768px) {
        margin-top: 0;
      }
    }

    &--light {
      background-color: #fff;

      .blog-cta-in-content__heading {
        color: #404040;
      }
    }
  }

  .blog-read-more-in-content {
    margin: 32px 0;
    padding: 20px;
    background: #fff;

    @media (min-width: 768px) {
      margin: 40px 0;
    }

    @media (min-width: 1200px) {
      padding: 30px 50px;
    }

    &__label {
      display: block;
      color: #404040;
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    &__link {
      display: block;
      margin-top: 24px;
      color: #404040;
      border: none !important;
      font-size: 18px !important;
      font-weight: bold;
      line-height: 1.2 !important;
      text-decoration: none;
      letter-spacing: 0.18px;

      @media (min-width: 768px) {
        font-size: 22px !important;
      }

      &:hover {
        .icon {
          padding-left: 44px;

          &::after {
            width: 40px;
          }

          svg path {
            stroke: #f39200;
          }
        }
      }
    }

    &__icon {
      margin-top: 13px;

      .icon {
        position: relative;
        padding-left: 24px;
        transition: 0.2s ease-in;

        svg path {
          transition: 0.2s ease-in;
        }
      }
    }
  }

  .fc-accent {
    color: #f39200;
  }

  .blog-article-conclusion {
    margin: 32px 0;
    padding: 22px 16px 22px 30px;
    background-color: #fff;

    @media (min-width: 768px) {
      margin: 40px 0;
      padding: 32px 32px 36px 42px;
    }

    @media (min-width: 1200px) {
      padding: 40px 40px 44px 48px;
    }

    &__title {
      position: relative;
      margin-top: 0 !important;
      margin-bottom: 24px !important;
      font-size: 32px;
      font-weight: bold;
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 36px;
      }
      @media (min-width: 1200px) {
        font-size: 40px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -46px;
        width: 36px;
        height: 36px;
        background: url('img/conclusion-ico.svg') center no-repeat;
        background-size: 32px 32px;

        @media (min-width: 768px) {
          left: -54px;
          width: 42px;
          height: 42px;
          background-size: 42px 42px;
        }
      }
    }

    &__text {
      color: #404040;

      p {
        color: #404040;

        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .freq-ask-section {
    margin: 32px 0;

    @media (min-width: 768px) {
      margin: 40px 0;
    }

    &__title {
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0.06em;
      text-transform: uppercase;
    }

    &__inner {
      margin-top: 30px;

      .solutions-list {
        margin: 0 !important;
        padding: 0 !important;

        li {
          margin-bottom: 0;
          padding-left: 0;

          &::before {
            display: none;
          }

          p {
            &:last-child {
              margin-bottom: 0 !important;
            }
          }
        }

        .solutions-list__item {
          & + .solutions-list__item {
            margin-top: 24px;
          }
        }
      }
    }
  }

  .lg-pdf-download-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 32px 0;
    padding: 16px;
    background: #fff;
    border: 2px solid #f39200;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    transition: 0.3s ease-in;
    -webkit-appearance: none;

    @media (min-width: 768px) {
      margin: 40px 0;
      padding: 56px;
    }

    &:hover {
      background-color: lighten(#f39200, 50);
    }

    &__img {
      width: 80px;
      height: auto;
      margin-right: 20px;

      @media (min-width: 768px) {
        width: 80px;
        height: auto;
        margin-right: 40px;
      }
    }

    &__label {
      color: #f39200;
      font-size: 13px;
      font-weight: 700;
      line-height: 1.6;
      letter-spacing: 1.5px;
      text-transform: uppercase;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }
  }
}
