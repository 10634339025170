/* stylelint-disable no-duplicate-selectors */
:root {
  /* default */
  --link-default-color: var(--color-blue-primary);
  --link-default-hover-color: var(--color-blue-primary-light);
  --link-default-active-color: var(--color-blue-primary-dark);
}

h1:not([class]),
h2:not([class]),
h3:not([class]),
h4:not([class]),
h5:not([class]),
h6:not([class]),
ol:not([class]),
ul:not([class]),
p:not([class]) {
  margin: 0;
  padding: 0;
}

.Article {
  position: relative;
  z-index: 5;
  color: var(--color-charcoal);
  font-size: 18px;
  line-height: 1.34;

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }

  &_size {
    &_s {
      font-size: 16px;
      line-height: 1;

      @media screen and (max-width: 767px) {
        font-size: 13px !important;
        line-height: 16px;
        letter-spacing: 0.16px;
      }
    }
  }

  .container-fluid {
    padding: 0;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  a.external-link,
  a:not([class]) {
    color: var(--color-blue-primary) !important;
    border-bottom: 1px solid;
    border-bottom-color: currentColor !important;
    text-decoration: none;
    transition: border 0.2s ease, color 0.2s ease;

    &:hover {
      color: var(--color-blue-primary-light) !important;
    }

    &:active {
      color: var(--color-blue-primary-dark) !important;
    }

    &:visited {
      color: #70529f;
      border-bottom-color: #70529f;
    }
  }

  p {
    margin: 9px 0;

    > iframe {
      max-width: 100%;

      @media screen and (max-width: 768px) {
        height: 50vw;
      }
    }
  }

  h2 {
    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xl);
    font-size: 30px;
    font-weight: var(--font-weight-text-bold);
    line-height: 1.07;

    @media screen and (min-width: 768px) and (max-width: 1199px) {
      font-size: 26px;
      line-height: 32px;
    }

    @media screen and (max-width: 767px) {
      font-size: 22px;
      line-height: 24px;
    }
  }

  h3 {
    margin-top: var(--space-5xl);
    margin-bottom: var(--space-xl);
    font-size: 26px;
    line-height: 1.24;

    @media screen and (min-width: 16000px) {
      font-size: 32px;
      line-height: 40px;
    }

    @media screen and (max-width: 767px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  h4 {
    margin-top: var(--space-5xl);
    margin-bottom: var(--space-xl);
    font-size: 20px;
    line-height: 1.2;

    @media screen and (min-width: 16000px) {
      font-size: 24px;
      line-height: 28px;
    }

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  h5 {
    color: var(--color-charcoal);
    font-size: 22px;
    font-weight: normal;
    line-height: 1.27;
    letter-spacing: 0.3px;
  }

  ul.styled-list,
  ul:not([class]) {
    padding-left: 0;
    list-style-type: none;
  }

  ul.styled-list,
  ul:not([class]) {
    margin: 16px 0 32px;

    @media screen and (max-width: 767px) {
      margin-bottom: 40px;
    }

    > li {
      position: relative;
      padding-left: 32px;

      @media screen and (max-width: 767px) {
        padding-left: 24px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 8px;
        margin-top: 10px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &:not(:first-child) {
        margin-top: 8px;
      }

      &:nth-child(4n + 1) {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='7' height='8' viewBox='0 0 7 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M0.5 4L6.5 0L6.5 8L0.5 4Z' fill='%23F0503C'/%3E%3C/svg%3E%0A");
          background-size: 7px 8px;
        }
      }

      &:nth-child(4n + 2) {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M8 0L4.24655 6H0L3.75345 0H8Z' fill='%23284086'/%3E%3C/svg%3E%0A");
          background-size: 8px 6px;
        }
      }

      &:nth-child(4n + 3) {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M6 0V6H0V0H6Z' fill='%232BC6BF'/%3E%3C/svg%3E%0A");
          background-size: 6px 6px;
        }
      }

      &:nth-child(4n + 4) {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M4 6.5L0 0.5H8L4 6.5Z' fill='%23FFB133'/%3E%3C/svg%3E%0A");
          background-size: 8px 7px;
        }
      }
    }
  }

  ul.styled-list.inline-styled-list {
    display: inline-block;
    margin: calc(var(--space-2xs) * -1) calc(var(--space-xs) * -1);

    > li {
      display: inline-block;
      margin: var(--space-2xs) var(--space-xs);
      padding-left: var(--space-m);

      &::before {
        margin-top: 8px;
      }
    }
  }

  li > *:first-child {
    margin-top: 0;
  }

  ol.list-numeric-secondary {
    counter-reset: section;
    margin: var(--space-5xl) 0 var(--space-xxl);
    padding: 0;
    list-style-type: none;

    > li {
      position: relative;
      padding-left: 8px + 24px;

      &::before {
        content: counter(section);
        counter-increment: section; /* Инкрементирует счётчик */
        position: absolute;
        top: 5px;
        left: 0;
        font-size: 12px;
        font-weight: var(--font-weight-text-bold);
        line-height: 1.33;
        letter-spacing: 0.6px;
      }

      &:not(:first-child) {
        margin-top: var(--space-6xl);
      }
    }
  }

  ol:not([class]) {
    counter-reset: list;
    margin: var(--space-m) 0;

    > li {
      position: relative;
      padding-left: var(--space-2xl);

      &::before {
        content: counter(list) '. ';
        counter-increment: list;
        position: absolute;
        top: 0;
        left: 0;
        color: var(--color-blue-primary);
        font-weight: 600;
      }

      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    > li > ol {
      counter-reset: list2;

      > li::before {
        content: counter(list) '.' counter(list2) ' ';
        counter-increment: list2;
      }

      > li > ol {
        counter-reset: list3;

        > li::before {
          content: counter(list) '.' counter(list2) '. ' counter(list3);
          counter-increment: list3;
        }
      }
    }
  }

  blockquote {
    margin: var(--space-xl) 0;
    padding-left: 28px;
    border-left: 4px solid var(--color-bright-orange);

    @media screen and (max-width: 767px) {
      padding-left: 22px;
      border-width: 2px;
    }

    &.blockquote_ghost {
      padding-left: var(--space-2xl);
      border-left: 0;
      font-size: var(--size-text-2xl);
      line-height: 1.28;
      letter-spacing: 0.3px;

      @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: var(--size-text-xl);
      }

      @media screen and (max-width: 767px) {
        font-size: var(--size-text-l);
        line-height: 24px;
      }
    }
  }

  .Article-Files {
    margin: var(--space-2xl) 0 var(--space-3xl);

    @media screen and (max-width: 767px) {
      margin: var(--space-2xl) 0;
    }
  }

  .Article-Quote {
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-6xl);

    &-Text {
      position: relative;
      padding-left: var(--space-7xl);

      @media screen and (max-width: 1199px) {
        padding-left: var(--space-5xl);
      }

      @media screen and (max-width: 767px) {
        padding-left: var(--space-4xl);
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 25px;
        height: 20px;
        margin-top: 5px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='25px' height='20px' viewBox='0 0 25 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='1.-All-Pages' stroke='none' stroke-width='1' fill='none' fillRule='evenodd'%3E%3Cg id='COM/Blog_Article_1300' transform='translate(-40.000000, -2260.000000)' fill='%23284086'%3E%3Cg id='Group-21' transform='translate(40.000000, 1440.000000)'%3E%3Cg id='quote' transform='translate(0.000000, 816.000000)'%3E%3Cpath d='M10,4 L10,24 L0,14 L0,4 L10,4 Z M15,4 L25,4 L25,24 L15,14 L15,4 Z' id='quotation-marks'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    &-User {
      margin-top: var(--space-m);

      .User-Block:first-child {
        padding-left: var(--space-7xl);

        @media screen and (max-width: 1199px) {
          padding-left: var(--space-5xl);
        }

        @media screen and (max-width: 767px) {
          padding-left: var(--space-4xl);
        }
      }

      .User-Block:not(:first-child) {
        @media screen and (max-width: 767px) {
          margin-left: var(--space-xs);
        }
      }

      .Avatar_size_m {
        width: 55px;
        height: 55px;

        @media screen and (max-width: 1199px) {
          width: 48px;
          height: 48px;
        }

        @media screen and (max-width: 767px) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  figure {
    margin: var(--space-3xl) 0 var(--space-6xl);
    padding: var(--space-xl) var(--space-2xl) var(--space-2xl);
    background: #fff;
    border: 1px solid var(--color-decor);
    border-radius: 2px;

    @media screen and (max-width: 767px) {
      padding: var(--space-m);
    }

    &.figure-secondary {
      padding: 0;
      background-color: transparent;
      border: 0;

      figcaption {
        margin-top: var(--space-2m);
      }
    }

    iframe {
      width: 100%;
      height: 448px;
    }

    p {
      margin: 0;
    }

    img {
      display: block;
      margin: 0 auto;
    }

    figcaption {
      margin-top: var(--space-xl);
      padding-top: calc(var(--space-m) - 1px);
      color: var(--color-web);
      border-top: 1px solid #a2a8aa;
      font-size: 16px;
      line-height: 1;
      letter-spacing: 0.2px;

      @media screen and (max-width: 767px) {
        font-size: 13px;
      }
    }
  }

  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  h2 {
    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xl);
  }

  h4 {
    font-size: var(--size-text-xs);
    font-weight: bold;
    line-height: var(--size-text-m);
    letter-spacing: 0.6px;
    text-transform: uppercase;
  }

  .blog-read-more-in-content {
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-6xl);
    padding: var(--space-2xl) var(--space-2xl) var(--space-xl);
    background: #fff;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 14px 30px 0 rgba(157, 167, 170, 0.08),
      0 4px 8px 0 rgba(157, 167, 170, 0.16);

    @media screen and (max-width: 767px) {
      padding: var(--space-xl) var(--space-m) var(--space-m);
    }

    &__label {
      display: block;
      margin-bottom: var(--space-xs);
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    &__link {
      display: block;
      color: var(--color-reach-black) !important;
      border: 0;
      font-size: 30px;
      font-weight: 700;
      line-height: 1.07;
      text-decoration: none;

      @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: 26px;
        line-height: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 22px;
        line-height: 24px;
      }
    }

    &__icon {
      margin-top: 16px;

      .icon {
        display: inline-block;
        width: 24px;
        height: 16px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24px' height='16px' viewBox='0 0 24 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='1.-All-Pages' stroke='none' stroke-width='1' fill='none' fillRule='evenodd'%3E%3Cg id='COM/Blog_Article_1300' transform='translate(-72.000000, -3224.000000)' fill='%230F161A'%3E%3Cg id='Group-4' transform='translate(40.000000, 2616.000000)'%3E%3Cg id='recommended-article' transform='translate(0.000000, 504.000000)'%3E%3Cg id='Button/Icon+Text' transform='translate(32.000000, 104.000000)'%3E%3Cg id='Group' transform='translate(0.000000, 2.000000)'%3E%3Cpolygon id='Path' fillRule='nonzero' points='24 6 18 12 18 6'%3E%3C/polygon%3E%3Cpolygon id='Path' fillRule='nonzero' points='12 12 18 6 18 12'%3E%3C/polygon%3E%3Cpolygon id='Path' points='0 4 0 8 15 7 24 6 15 5'%3E%3C/polygon%3E%3Cpolygon id='Path' fillRule='nonzero' points='12 7.15493e-08 18 6 18 0'%3E%3C/polygon%3E%3Cpolygon id='Path' fillRule='nonzero' points='24 6 18 0 18 6'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        > * {
          display: none;
        }
      }
    }
  }
}

.Article-ContentPosts {
  margin-top: var(--space-3xl);
  margin-bottom: var(--space-xxl);
}

.Article-ContentClients {
  margin: var(--space-3xl) 0;

  .GridItem {
    text-align: center;

    &:not(:last-child) {
      border-right: 0.5px solid var(--color-gainsboro);
    }

    @media screen and (min-width: 1200px) {
      &:nth-child(4n) {
        border-right: 0;
      }

      &:not(:nth-child(-n + 4)) {
        border-top: 0.5px solid var(--color-gainsboro);
      }
    }

    @media screen and (max-width: 1199px) {
      &:nth-child(-n + 2) {
        border-top: 1px solid var(--color-gainsboro);
      }

      &:nth-child(2n) {
        border-right: 0;
      }

      &:not(:nth-child(-n + 2)) {
        border-top: 0.5px solid var(--color-gainsboro);
      }
    }
  }
}

.Article-Banner {
  margin-top: var(--space-3xl);
  margin-bottom: var(--space-xxl);
}

.Article .table-wrapper {
  overflow-x: auto;
  margin-top: var(--space-3xl);
  margin-bottom: var(--space-6xl);

  @media screen and (max-width: 767px) {
    margin: 0 calc(var(--space-m) * -1);
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-6xl);
    padding: 0 var(--space-m);
  }

  table {
    border-spacing: 0;

    th {
      padding-bottom: 10px;
      color: #0f161a;
      border-bottom: 8px solid var(--color-charcoal);
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      letter-spacing: 0.86px;
    }

    td {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.2px;
    }

    th,
    td {
      padding-right: 16px;
    }

    tr:not(:first-child):not(:last-child) {
      td {
        border-bottom: 1px solid var(--color-charcoal);
      }
    }

    tr:not(:first-child) {
      td {
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }
  }
}

.Article-Vacancy {
  &-Title {
    margin-bottom: var(--space-xl);
  }
}

.Article-SideItemWrapper {
  position: relative;

  @media screen and (min-width: 1200px) {
    .Article-SideItemInner {
      position: absolute;
      top: 0;
      left: 100%;
      width: calc(100% / 2);
      padding-left: var(--space-5xl);

      .Article-SideItem {
        margin: 0;
      }
    }
  }
}

.SiteBlock.Article-SideItem {
  .BlogCard,
  .CaseCard {
    @media screen and (max-width: 1199px) {
      max-width: 336px;
    }
  }
}

.Article-ValuesCard {
  margin-bottom: var(--space-3xl);
}

.iframe-container {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
