.VacancyCard {
  position: relative;
  padding: var(--space-xl) var(--space-2xl);
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(157, 167, 170, 0.16),
    0 14px 30px 0 rgba(157, 167, 170, 0.08);
  transition: 0.3s all ease-in-out;

  @media screen and (max-width: 767px) {
    padding: var(--space-xl);
  }

  &_view {
    &_ghost {
      padding: 0;
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }

  &_link {
    &:not(.VacancyCard_view_ghost):hover {
      z-index: 1;
      box-shadow: 0 14px 30px 0 rgba(157, 167, 170, 0.2),
        0 4px 8px 0 rgba(157, 167, 170, 0.3);
    }

    &:hover {
      .VacancyCard-Title {
        color: var(--color-blue-primary);
      }
    }
  }

  &-Link {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &-Title {
    margin-bottom: var(--space-m);

    @media screen and (max-width: 767px) {
      margin-bottom: var(--space-s);
    }
  }

  &-Cities {
    display: inline-flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    flex-flow: wrap;

    li {
      color: var(--color-charcoal);
      font-size: 16px;
      line-height: 1;
      letter-spacing: 0.2px;

      @media screen and (max-width: 767px) {
        font-size: 12px;
      }

      &:not(:last-child)::after {
        content: ' / ';
        margin-right: 5px;
      }
    }
  }

  &-Text {
    margin-top: var(--space-m);
  }
}
