.Select {
  --caption-margin-left: 0;
  --select-arrow-color: var(--color-reach-black);
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  &_size {
    &_m {
      --input-height: calc(var(--control-height-m));
      --input-font-size: var(--size-text-xs);
      --input-space: var(--space-m);
      --tag-space: calc(var(--space-s) / 2 - var(--control-border-width));
    }

    &_l {
      --input-height: calc(var(--control-height-m));
      --input-font-size: var(--size-text-xs);
      --input-space: var(--space-m);
      --tag-space: calc(var(--space-s) / 2 - var(--control-border-width));
    }
  }

  &_labelPosition {
    &_top {
      flex-direction: column;

      & > *:not(:last-child) {
        margin-bottom: var(--space-xs);
      }
    }

    &_left {
      flex-direction: row;

      & .Select-Label {
        display: inline-flex;
        align-items: center;
        height: var(--input-height);
      }

      & > *:not(:last-child) {
        margin-right: var(--space-s);
      }
    }
  }

  &-Body {
    display: inline-flex;
    flex-direction: column;
    width: 100%;

    & .Select-Caption {
      margin-top: var(--space-2xs);
      margin-left: var(--caption-margin-left);
    }
  }

  &_view_transparent {
    width: auto;

    --select-arrow-color: inherit;

    .Select-Body {
      width: auto;
    }

    .Select-Control {
      --input-height: 40px;
      line-height: 1;
      align-items: center;
      min-width: 58px;
      justify-content: center;

      &[aria-expanded='true'] {
        --select-arrow-color: var(--color-reach-black);
        color: var(--color-reach-black);
        background-color: #fff;
        box-shadow: 0 4px 8px 0 rgba(157, 167, 170, 0.16),
          0 14px 30px 0 rgba(157, 167, 170, 0.08);
      }
    }

    .Select-ControlInner {
      width: auto;
    }

    .Select-Input {
      opacity: 0;
      visibility: hidden;
    }

    .Select-ControlValueContainer {
      position: relative;
      display: flex;
      align-items: center;
    }

    .Select-ControlValue {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.6px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .Select-IndicatorsDropdown {
      width: auto;
    }

    .Select-Indicators {
      margin-left: 3px;
    }
  }

  &-SelectContainer {
    position: relative;
    display: flex;
    width: 100%;

    &_status {
      &_alert {
        --container-border-color: var(--color-fire-opal);
        --select-arrow-color: var(--color-fire-opal);
      }
    }

    &_view_default .Select-Control {
      padding: 0;
      color: var(--color-control-typo-default);
      background: var(--color-control-bg-default);
      border: var(--control-border-width) solid var(--container-border-color);
      border-radius: var(--control-radius);

      &:hover {
        border-color: var(--color-control-bg-border-default-hover);
      }
    }

    &_view_default.Select-SelectContainer_focused .Select-Control {
      z-index: 1;
      border-color: var(--color-control-bg-border-focus);
      outline: none;
    }

    &_multiple .Select-Control {
      height: auto;
      min-height: var(--input-height);
    }

    &_form {
      &_round .Select-Control {
        padding-left: calc(var(--input-space) * 1.6);
        border-radius: calc(var(--input-height) / 2);
      }

      &_brick .Select-Control {
        border-radius: 0;
      }

      &_clear .Select-Control {
        border-width: 0;
        border-bottom-width: var(--control-border-width);
        border-radius: 0;
      }

      &_defaultClear .Select-Control {
        border-right-width: 0;
        border-radius: var(--control-radius) 0 0 var(--control-radius);
      }

      &_clearDefault .Select-Control {
        border-left-width: 0;
        border-radius: 0 var(--control-radius) var(--control-radius) 0;
      }

      &_defaultBrick .Select-Control {
        border-radius: var(--control-radius) 0 0 var(--control-radius);
      }

      &_brickDefault .Select-Control {
        border-radius: 0 var(--control-radius) var(--control-radius) 0;
      }

      &_roundBrick .Select-Control {
        padding-left: calc(var(--input-space) * 1.6);
        border-radius: calc(var(--input-height) / 2) 0 0
          calc(var(--input-height) / 2);
      }

      &_brickRound .Select-Control {
        padding-right: calc(var(--input-space) * 1.6);
        border-radius: 0 calc(var(--input-height) / 2) 99rem
          calc(var(--input-height) / 2);
      }

      &_brickClear .Select-Control {
        border-right-width: 0;
        border-radius: 0;
      }

      &_clearBrick .Select-Control {
        border-left-width: 0;
        border-radius: 0;
      }

      &_clearRound .Select-Control {
        padding-right: calc(var(--input-space) * 1.6);
        border-left-width: 0;
        border-radius: 0 calc(var(--input-height) / 2)
          calc(var(--input-height) / 2) 0;
      }

      &_roundClear .Select-Control {
        padding-left: calc(var(--input-space) * 1.6);
        border-right-width: 0;
        border-radius: calc(var(--input-height) / 2) 0 0
          calc(var(--input-height) / 2);
      }

      &_clearClear .Select-Control {
        border-width: 0;
        border-top-width: var(--control-border-width);
        border-bottom-width: var(--control-border-width);
        border-radius: 0;
      }
    }

    &_view_default.Select-SelectContainer_form_clear.Select-SelectContainer_focused
      .Select-Control,
    &_view_default.Select-SelectContainer_form_clearClear.Select-SelectContainer_focused
      .Select-Control {
      box-shadow: var(--control-border-width) 0
          var(--color-control-bg-border-focus),
        calc(-1 * var(--control-border-width)) 0
          var(--color-control-bg-border-focus);
    }

    &_view_default.Select-SelectContainer_form_defaultClear.Select-SelectContainer_focused
      .Select-Control,
    &_view_default.Select-SelectContainer_form_brickClear.Select-SelectContainer_focused
      .Select-Control,
    &_view_default.Select-SelectContainer_form_roundClear.Select-SelectContainer_focused
      .Select-Control {
      box-shadow: var(--control-border-width) 0
        var(--color-control-bg-border-focus);
    }

    &_view_default.Select-SelectContainer_form_clearDefault.Select-SelectContainer_focused
      .Select-Control,
    &_view_default.Select-SelectContainer_form_clearBrick.Select-SelectContainer_focused
      .Select-Control,
    &_view_default.Select-SelectContainer_form_clearRound.Select-SelectContainer_focused
      .Select-Control {
      box-shadow: calc(-1 * var(--control-border-width)) 0
        var(--color-control-bg-border-focus);
    }

    &_view_default.Select-SelectContainer_disabled .Select-Control {
      color: var(--color-control-typo-disable);
      background: var(--color-control-bg-disable);
      border-color: var(--color-control-bg-border-disable);
      pointer-events: none;
    }

    &_view_clear .Select-Control,
    &_view_clear .Select-Control:hover,
    &_view_clear .Select-Control:focus {
      padding: 0;
      color: var(--color-control-typo-default);

      &.Select-SelectContainer_disabled {
        pointer-events: none;

        & .Select-Control {
          color: var(--color-control-typo-disable);
        }
      }
    }

    &_view_clear.Select-SelectContainer_disabled .Select-Control {
      pointer-events: none;
    }

    &_view_default .Select-ControlValueContainer {
      padding: 0 0 0 var(--input-space);
    }

    &_form_round &-IndicatorsDropdown {
      border-radius: 0 calc(var(--input-height) / 2)
        calc(var(--input-height) / 2) 0;
    }

    &_multiple .Select-ControlValueContainer {
      position: relative;
      display: flex;
      box-sizing: border-box;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 1;
    }

    &_multiple .Select-ControlValue {
      overflow: visible;
      padding-top: calc(var(--tag-space) - var(--control-border-width));
      white-space: normal;

      &:not(.Select-ControlValue_groupsValue) {
        display: flex;
        align-items: center;
        flex-flow: wrap;
      }
    }

    &_multiple .Select-ControlValue_isUserSelect {
      display: flex;
      padding-top: calc(var(--space-s) / 4);
      flex-wrap: wrap;
    }
  }

  &-Control {
    display: inline-flex;
    box-sizing: border-box;
    width: 100%;
    height: var(--input-height);
    padding: 0;
    color: currentColor;
    background: transparent;
    border: none;
    outline: none;
    font-family: var(--font-primary);
    font-weight: var(--font-weight-text-regular);
    line-height: calc(var(--input-height) - var(--control-border-width));
    cursor: pointer;
    transition: border-color 0.15s, box-shadow 0.15s, background-color 0.15s;

    &:focus {
      outline: none;
    }
  }

  &_multiple &-Control {
    height: auto;
    min-height: var(--input-height);
  }

  &_view_outline {
    --control-border-width: 1px;
    --container-border-color: var(--color-reach-black);

    .Select-Control {
      border: var(--control-border-width) solid var(--container-border-color);
      border-radius: var(--control-radius);
    }

    .Select-ControlValueContainer {
      padding: 0 0 0 var(--input-space);
    }

    .Select-SelectContainer.Select-SelectContainer_disabled .Select-Control {
      opacity: 0.3;
    }

    .Select-Input,
    .Select-ControlValue {
      font-size: 22px;
      letter-spacing: 0.3px;
    }
  }

  &_view_default .Select-Control {
    padding: 0;
    color: var(--color-control-typo-default);
    background: var(--color-control-bg-default);
    border-radius: var(--control-radius);

    &:hover {
      background: var(--color-control-bg-default-hover);
    }
  }

  &_view_default.Select_focused .Select-Control {
    z-index: 1;
    background: var(--color-control-bg-default-hover);
    outline: none;
  }

  &_form {
    &_round .Select-Control {
      padding-left: calc(var(--input-space) * 1.6);
      border-radius: calc(var(--input-height) / 2);
    }

    &_brick .Select-Control {
      border-radius: 0;
    }

    &_clear .Select-Control {
      border-width: 0;
      border-bottom-width: var(--control-border-width);
      border-radius: 0;
    }

    &_defaultClear .Select-Control {
      border-right-width: 0;
      border-radius: var(--control-radius) 0 0 var(--control-radius);
    }

    &_clearDefault .Select-Control {
      border-left-width: 0;
      border-radius: 0 var(--control-radius) var(--control-radius) 0;
    }

    &_defaultBrick .Select-Control {
      border-radius: var(--control-radius) 0 0 var(--control-radius);
    }

    &_brickDefault .Select-Control {
      border-radius: 0 var(--control-radius) var(--control-radius) 0;
    }

    &_roundBrick .Select-Control {
      padding-left: calc(var(--input-space) * 1.6);
      border-radius: calc(var(--input-height) / 2) 0 0
        calc(var(--input-height) / 2);
    }

    &_brickRound .Select-Control {
      padding-right: calc(var(--input-space) * 1.6);
      border-radius: 0 calc(var(--input-height) / 2) 99rem
        calc(var(--input-height) / 2);
    }

    &_brickClear .Select-Control {
      border-right-width: 0;
      border-radius: 0;
    }

    &_clearBrick .Select-Control {
      border-left-width: 0;
      border-radius: 0;
    }

    &_clearRound .Select-Control {
      padding-right: calc(var(--input-space) * 1.6);
      border-left-width: 0;
      border-radius: 0 calc(var(--input-height) / 2)
        calc(var(--input-height) / 2) 0;
    }

    &_roundClear .Select-Control {
      padding-left: calc(var(--input-space) * 1.6);
      border-right-width: 0;
      border-radius: calc(var(--input-height) / 2) 0 0
        calc(var(--input-height) / 2);
    }

    &_clearClear .Select-Control {
      border-width: 0;
      border-top-width: var(--control-border-width);
      border-bottom-width: var(--control-border-width);
      border-radius: 0;
    }
  }

  &_view_default.Select_form_clear.Select_focused .Select-Control,
  &_view_default.Select_form_clearClear.Select_focused .Select-Control {
    box-shadow: var(--control-border-width) 0
        var(--color-control-bg-border-focus),
      calc(-1 * var(--control-border-width)) 0
        var(--color-control-bg-border-focus);
  }

  &_view_default.Select_form_defaultClear.Select_focused .Select-Control,
  &_view_default.Select_form_brickClear.Select_focused .Select-Control,
  &_view_default.Select_form_roundClear.Select_focused .Select-Control {
    box-shadow: var(--control-border-width) 0
      var(--color-control-bg-border-focus);
  }

  &_view_default.Select_form_clearDefault.Select_focused .Select-Control,
  &_view_default.Select_form_clearBrick.Select_focused .Select-Control,
  &_view_default.Select_form_clearRound.Select_focused .Select-Control {
    box-shadow: calc(-1 * var(--control-border-width)) 0
      var(--color-control-bg-border-focus);
  }

  &_view_default.Select_disabled .Select-Control {
    color: var(--color-control-typo-disable);
    background: var(--color-control-bg-disable);
    border-color: var(--color-control-bg-border-disable);
    pointer-events: none;
  }

  &_view_clear .Select-Control,
  &_view_clear .Select-Control:hover,
  &_view_clear .Select-Control:focus {
    padding: 0;
    color: var(--color-control-typo-default);

    &.Select_disabled {
      pointer-events: none;

      & .Select-Control {
        color: var(--color-control-typo-disable);
      }
    }
  }

  &_view_clear.Select_disabled .Select-Control {
    pointer-events: none;
  }

  &-Indicators {
    display: flex;
    height: 100%;
  }

  &-IndicatorsDropdown {
    display: block;
    width: var(--input-height);
    margin: 0;
    padding: 0;
    color: var(--select-arrow-color);
    background-color: transparent;
    border: none;
    text-align: center;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &-ControlInner {
    position: relative;
    width: 100%;
  }

  &-ControlValueContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    min-height: calc(var(--input-height) - 2px);
    margin: 0;
    padding: 0;
    color: inherit;
    background-color: transparent;
    border: none;
    font-size: var(--input-font-size);
    line-height: calc(var(--input-height) - 2px);
    text-align: left;

    &:focus {
      outline: none;
    }
  }

  &_view_default .Select-ControlValueContainer {
    padding: 0 0 0 var(--input-space);
  }

  &_multiple &-ControlValueContainer {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
  }

  &-Control[aria-expanded='true'] &-IndicatorsDropdown {
    transform: rotate(180deg);
  }

  &-ControlValue,
  &-Placeholder {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &_multiple &-ControlValue {
    overflow: visible;
    padding-top: calc(var(--tag-space) - var(--control-border-width));
    white-space: normal;
  }

  &_multiple &-ControlValue_isUserSelect {
    display: flex;
    flex-wrap: wrap;
    padding-top: calc(var(--space-s) / 4);
  }

  &-Placeholder {
    padding-right: var(--input-space);
    color: var(--color-control-typo-placeholder);
    font-size: var(--input-font-size);
    text-align: left;
    pointer-events: none;
    text-transform: uppercase;
    font-weight: var(--font-weight-text-bold);

    &_selected {
      color: var(--color-blue-primary);
    }

    &_isHidden {
      visibility: hidden;
      opacity: 0;
    }
  }

  &-Control_hasInput &-Placeholder {
    position: absolute;
    top: 0;
    width: calc(100% - var(--input-space));
    background-color: transparent;
    line-height: calc(var(--input-height) - 2px);
  }

  &-DropdownIndicatorIcon {
    position: relative;
    top: 1px;
  }

  &-ClearIndicator {
    margin: 0;
    padding: 0 var(--space-xs);
    color: var(--color-control-typo-clear);
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &-ClearIndicatorIcon {
    position: relative;
    top: 1px;
  }

  &-Input {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    color: inherit;
    background-color: transparent;
    border: none;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    line-height: calc(var(--input-height) - 2px);

    &_hide {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      opacity: 0;
    }

    &_multiple {
      display: inline-flex;
      min-width: 10px;
      height: calc(var(--input-height) - (var(--control-border-width) * 2));
      margin-top: calc(var(--tag-space) * -1);
      line-height: 1;
      line-height: calc(
        var(--input-height) - (var(--control-border-width) * 2)
      );
    }

    &_multiple.Select-Input_isUserSelect {
      margin-top: calc(var(--space-s) / 4 * -1);
    }
  }

  &-FakeField {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
  }

  &-HelperInputFakeElement {
    position: absolute;
    top: 0;
    display: inline-block;
    visibility: hidden;
    overflow: hidden;
    height: 0;
    font-size: var(--input-font-size);
    white-space: nowrap;
  }
}
