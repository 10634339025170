.Footer {
  background-color: var(--color-decor);
  padding: var(--space-6xl) 0;
  position: relative;
  z-index: 111;

  @media (min-width: 1200px) {
    padding: var(--space-7xl) 0;
  }

  &-Socials {
    display: flex;
    flex-flow: wrap;
    width: 100%;

    & > * {
      margin: var(--space-2xs);
    }
  }

  &-Menu {
    margin-top: var(--space-4xl);

    @media (max-width: 767px) {
      padding-top: var(--space-4xl);
      border-top: 1px solid var(--color-gainsboro);
    }
  }

  &-Nav {
    display: grid;
    grid-gap: var(--space-xl);
    grid-template:
      'company services'
      'industries services'
      'pages services';
    grid-template-columns: 1fr 1fr;

    @media (min-width: 1200px) {
      grid-template:
        'company services industries'
        'pages services industries';
      grid-template-columns: 1fr 1.71fr 1fr;
      grid-template-rows: auto 1fr;
    }

    &-Column {
      display: flex;
      flex-direction: column;

      &:nth-child(1) {
        grid-area: company;
      }

      &:nth-child(2) {
        grid-area: pages;
      }

      &:nth-child(3) {
        grid-area: services;

        .Footer-Nav-List {
          @media (min-width: 1200px) {
            gap: 0 var(--space-xl);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }

      &:nth-child(4) {
        grid-area: industries;
      }
    }

    &-Title {
      margin-bottom: var(--space-m);
    }

    &-Primary {
      &:not(:first-child) {
        margin-top: var(--space-xs);
      }
    }

    &-List {
      margin-bottom: calc(var(--space-2xs) * -1);
    }

    &-Item {
      margin-bottom: var(--space-2xs);
    }

    &-See-All {
      margin-top: var(--space-xl);

      @media (min-width: 1200px) {
        grid-column-start: 2;
      }
    }
  }

  &-Bottom {
    margin-top: var(--space-4xl);

    @media screen and (max-width: 767px) {
      border-top: 1px solid var(--color-gainsboro);
      padding-top: var(--space-4xl);
    }
  }

  &-Locations {
    &-Name {
      margin-bottom: var(--space-m);
    }
  }

  &-Info {
    margin-top: 76px;

    @media screen and (max-width: 767px) {
      border-top: 1px solid var(--color-gainsboro);
      padding-top: var(--space-4xl);
      margin-top: var(--space-4xl);
    }
  }

  &-Langs {
    margin-top: -10px;
    margin-left: -10px;
  }

  &-Badges {
    margin-top: var(--space-4xl);

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
}
