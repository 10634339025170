.FeaturedCard {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: #fff;
  transition: 0.2s ease-in;
  border: 1px solid var(--color-platinum);

  &:hover {
    background-color: var(--color-platinum);

    .FeaturedCard-Title {
      color: var(--color-blue-primary);
    }
  }

  &-Inner {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--space-xl);
  }

  .Picture {
    display: flex;
    position: relative;
    height: 0;
    padding-bottom: 56%;

    picture {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &-Subtitle {
    margin-bottom: var(--space-m);
  }

  &-Title {
    transition: color 0.2s ease-in;
  }

  &-Top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
  }

  &-Tag,
  .TagGroup {
    margin-top: var(--space-xl);
    pointer-events: none;
    font-size: 12px;
  }
}
