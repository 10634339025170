.FormSuccess {
  display: flex;
  color: #fff;
  background-color: var(--color-blue-primary);
  flex-flow: column;

  &_view {
    &_overlay {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 89px;
    height: 87px;
    margin-top: auto;
    margin-left: auto;
    background-image: url('./form_lil.svg');
  }

  &-Title {
    margin-bottom: var(--space-m);
  }

  a {
    &:hover {
      color: var(--color-decor);
    }

    &:active {
      color: var(--color-decor);
    }
  }
}
