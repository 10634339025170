.GetInTouchForm {
  &-Title {
    margin-bottom: var(--space-m);
  }

  &-Form {
    margin-top: 112px;

    &_opened {
      margin-top: var(--space-2xl);

      &.GetInTouchForm-Form_view_columns {
        .GetInTouchForm {
          &-Body {
            display: flex;
            flex-flow: wrap;
            margin: 0 calc(var(--space-m) * -1);

            > * {
              width: 50%;
              padding: 0 var(--space-m);

              @media screen and (max-width: 1199px) {
                width: 100%;
              }
            }

            .Checkbox {
              align-items: center;
            }
          }
        }
      }
    }
  }

  &-Body {
    position: relative;

    .Pagination {
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
    }

    .TextField {
      margin-bottom: var(--space-m);
    }

    .Checkbox {
      margin-top: var(--space-xs);
    }
  }

  &-Bottom {
    margin-top: var(--space-xl);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      display: block;
      margin-top: 0;
    }
  }

  &-Toggle {
    @media screen and (max-width: 767px) {
      margin-bottom: var(--space-xl);
    }
  }

  &-Button {
    display: flex;

    @media screen and (max-width: 767px) {
      justify-content: flex-end;
    }

    > .Button:not(:first-child) {
      margin-left: var(--space-xs);
    }
  }

  &-Success {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-blue-primary);
    color: #fff;
    padding: var(--space-3xl);
    display: flex;
    flex-flow: column;

    &:after {
      content: '';
      width: 89px;
      height: 87px;
      background-image: url('/assets/img/form_lil.svg');
      display: block;
      margin-left: auto;
      margin-top: auto;
    }
  }

  &-ModalSuccess {
    background-color: var(--color-blue-primary);
    color: #fff;
    max-width: 600px;
    padding: var(--space-3xl);
    border-radius: 16px;

    &:after {
      content: '';
      width: 89px;
      height: 87px;
      background-image: url('/assets/img/form_lil.svg');
      display: block;
      margin-left: auto;
      margin-top: 109px;
    }
  }
}
