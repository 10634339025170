.CookiesPopup {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 28px 0 26px;
  z-index: 999;

  &-Buttons {
    @media (min-width: 768px) {
      display: flex;
      justify-content: flex-end;
    }

    @media (max-width: 767px) {
      margin-top: var(--space-xl);
    }

    .Button {
      justify-content: center;
      min-width: 184px;

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
