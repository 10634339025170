/* light */
@font-face {
  font-family: 'DA_FuturaPTWeb';
  src: url('./assets/fonts/DAFTN35W.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/DAFTN35W.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

/* book */
@font-face {
  font-family: 'DA_FuturaPTWeb';
  src: url('./assets/fonts/DAFTN45W.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/DAFTN45W.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* medium */
@font-face {
  font-family: 'DA_FuturaPTWeb';
  src: url('./assets/fonts/DAFTN55W.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/DAFTN55W.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

/* demi */
@font-face {
  font-family: 'DA_FuturaPTWeb';
  src: url('./assets/fonts/DAFTN65W.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/DAFTN65W.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'DA_FuturaPTWeb';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8fafa;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

* {
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 1304px;
  padding: 0 var(--space-3xl);

  @media screen and (max-width: 767px) {
    padding: 0 var(--space-m);
  }
}

img {
  max-width: 100%;
  height: auto;
}

picture {
  display: flex;
}

a {
  transition: 0.2s ease all;
}

a {
  color: inherit;
}

// slider fix
.swiper-button-next,
.swiper-button-prev {
  @media (max-width: 767px) {
    width: calc(var(--swiper-navigation-size) / 44 * 27) !important;
  }
}
