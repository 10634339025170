@use "sass:math";

.ModalPage {
  &_redirectForm {
    max-width: 600px;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;

    .ModalPage {
      &-Title {
        width: 100%;
      }

      &-SubTitle {
        margin-bottom: var(--space-m);
      }

      &-Button {
        margin-top: var(--space-6xl);
      }

      &-LeftSide {
        width: percentage(math.div(296, 520));

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      &-RightSide {
        width: percentage(math.div(184, 520));

        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
  }

  &_resource {
    @media (min-width: 768px) {
      &:not(.ModalPage_largeForm) {
        width: 492px;
      }
    }

    @media (min-width: 1200px) {
      &.ModalPage_largeForm {
        width: 936px;
      }

      .ModalPage-Body,
      form {
        height: 100%;
      }

      .ModalPage-Body {
        display: flex;
        justify-content: flex-end;
      }

      form {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      &.ModalPage_largeForm {
        form {
          width: 458px;
        }
      }
    }

    &:not(.ModalPage_largeForm) {
      .ModalPage-Text {
        margin-bottom: var(--space-xs);
      }
    }

    .TextField {
      margin-bottom: var(--space-m);
    }

    .ModalPage-Bottom {
      margin-top: var(--space-m);

      @media (min-width: 1200px) {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;

        .Checkbox {
          margin-bottom: var(--space-xs);
        }
      }
    }
  }

  &_webinar {
    max-width: 100%;

    .Picture {
      max-width: 184px;
    }
  }

  &_clutch {
    .swiper {
      margin-top: var(--space-3xl);

      @media (max-width: 1199px) {
        margin-bottom: var(--space-3xl);
      }
    }

    .ModalPage-Clutch-Pictures {
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      width: 232px;
      justify-content: space-between;

      @media (max-width: 375px) {
        width: 190px;
      }

      .Picture {
        width: 100px;
        height: auto;

        @media (max-width: 375px) {
          width: 80px;
        }

        &:nth-child(3),
        &:nth-child(4) {
          margin-top: var(--space-xl);
        }
      }
    }
  }

  &-MainTitle {
    margin-bottom: var(--space-m);
  }

  &-Title {
    margin-bottom: var(--space-m);
  }

  &-Text {
    margin-bottom: var(--space-4xl);
  }

  &-PicturePreview {
    @media (min-width: 1200px) {
      max-width: 250px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: calc(100% + 24px);
        bottom: -40px;
        width: 198px;
        height: 223px;
        pointer-events: none;
        background: url(arrow.svg) center no-repeat;
      }
    }

    &,
    picture {
      display: flex;
      align-items: flex-start;
    }

    picture {
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &-Bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--space-xs);

    @media screen and (max-width: 767px) {
      display: block;
    }

    .Button {
      margin-left: var(--space-2xl);

      @media screen and (max-width: 767px) {
        margin-left: 0;
        margin-top: var(--space-2xl);
      }
    }
  }

  &-Slider {
    margin-top: var(--space-m);
    padding-bottom: var(--space-3xl);
    overflow: hidden;

    &-Title {
      margin-top: var(--space-m);
    }

    p {
      margin: 0;
    }

    .swiper-pagination {
      bottom: 0;
      left: -6px;
      text-align: left;
    }
  }

  &-Success {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-blue-primary);
    color: #fff;
    padding: var(--space-3xl);
    display: flex;
    flex-flow: column;
    z-index: 5;

    &:after {
      content: '';
      width: 89px;
      height: 87px;
      background-image: url('/assets/img/form_lil.svg');
      display: block;
      margin-left: auto;
      margin-top: auto;
    }
  }
}
