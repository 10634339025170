.swiper-slide,
.GridItem {
  .ProjectCard {
    height: 100%;
  }
}

.ProjectCard {
  position: relative;
  background-color: #fff;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: 0 14px 30px 0 rgba(157, 167, 170, 0.08),
    0 4px 8px 0 rgba(157, 167, 170, 0.16);
  transition: 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(157, 167, 170, 0.3),
      0 14px 30px 0 rgba(157, 167, 170, 0.2);

    .ProjectCard-Button .Button {
      color: var(--color-blue-primary);
    }
  }

  &-OverlayLink {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &-Picture {
    overflow: hidden;

    + .ProjectCard-Inner {
      .ProjectCard-Button {
        position: absolute;
        left: var(--space-xl);
        bottom: var(--space-xl);
        padding-top: 0;
      }
    }
  }

  &-Inner {
    display: flex;
    padding: var(--space-xl) var(--space-xl)
      calc(var(--space-xl) + var(--space-xl) + var(--space-6xl));
    flex-flow: column;
  }

  &-Links {
    position: relative;
    z-index: 3;
    margin-top: var(--space-l);

    &-Item {
      &:not(:first-child) {
        margin-top: var(--space-s);
      }
    }
  }

  &-Button {
    margin-top: auto;
    padding-top: 54px;
  }
}
