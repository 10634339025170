.Theme .page-inner {
  padding-top: calc(var(--header-height) + 80px);
}

.HeaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999111;

  &_isSearchActive {
    .Header {
      background-color: #fff !important;
    }
  }

  &-Search {
    background-color: transparent;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in;
    cursor: pointer;

    @media (max-width: 1199px) {
      margin-right: var(--space-xl);
    }

    svg {
      &:last-child {
        display: none;
      }
    }

    &:hover:not(.HeaderWrapper-Search_active) {
      background-color: var(--color-blue-primary-light10);
    }

    &_active {
      background-color: var(--color-reach-black);

      svg {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: block;
        }
      }
    }
  }
}

.Header {
  position: static !important;
  z-index: 0 !important;
}

.Header-Languages {
  margin-right: var(--space-xl);

  .Select-Control[aria-expanded='true'] {
    .Select-ControlValue {
      color: #000 !important;
    }
  }
}

.Header-Burger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.Header-Languages,
.Header-Burger {
  position: relative;

  .Tooltip {
    position: absolute;
    top: 142%;
    right: 4px;
    width: 288px;
    transition: opacity 1s ease-in;

    &-Hidden {
      opacity: 0;
      pointer-events: none;
    }

    @media (min-width: 768px) {
      width: 390px;
    }

    @media (min-width: 1200px) {
      right: 38px;
      top: 120%;
    }

    .Tooltip-Arrow {
      display: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: -16px;
      right: 0;
      width: 16px;
      height: 16px;
      background: var(--tooltip-bg-color);
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }
  }
}

.HeaderMenu-SubItem {
  .Button.active {
    color: #fff;
  }
}
