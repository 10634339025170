/* styles.css or your stylesheet */
.typing-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.typing-indicator span {
  animation: typingAnimation 1.2s infinite;
  margin: 0 2px;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.4s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.8s;
}

@keyframes typingAnimation {
  0%,
  100% {
    opacity: 0;
  }
  20%,
  80% {
    opacity: 1;
  }
}
