// button
.da-chatbot-handler {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #284086 url(./da-chatbot-handler.svg) center top 14px no-repeat;
  transition: background-color 0.2s ease-in;
  cursor: pointer;
  border: none;
  -webkit-appearance: none;
  box-shadow: none;
  z-index: 112;

  &:hover {
    background-color: #3453ad;
  }

  &:focus {
    background-color: #213773;
  }
}

// popup window
.da-chatbot-popup {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 112;
  background: #fff;
  opacity: 0;
  transform: translateY(110%);
  transition: opacity 0.15s 0.15s ease-out, transform 0.35s ease-out;

  @media (max-height: 563px) {
    height: calc(100% - 110px);
  }

  @media (min-height: 564px) {
    height: 440px;
  }

  @media (max-width: 767px) {
    height: 100vh;
  }

  @media (min-width: 768px) {
    border: 0.2px solid #858585;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    bottom: 90px;
    right: 24px;
    width: 320px;
    border-radius: 8px;
    overflow: hidden;
  }

  &--active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.15s ease-in, transform 0.35s ease-in;
  }
}

// top part of the popup
.da-chatbot-top {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 12px 14px;
  background-color: #284086;
  position: relative;
}

// close button
.da-chatbot-close {
  position: absolute;
  top: 12px;
  left: 14px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  -webkit-appearance: none;
  box-shadow: none;
  background: rgba(#fff, 0.3) url(./da-chatbot-close.svg) center no-repeat;
  transition: background-color 0.2s ease-in;

  &:hover {
    background-color: rgba(#fff, 0.2);
  }

  &:focus {
    background-color: rgba(#fff, 0.4);
  }
}

// chatbot name
.da-chatbot-heading {
  font-family: 'DA_FuturaPT', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

// wrapper of the main content
.da-chatbot-inner {
  height: calc(100% - 56px);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 16px;
  position: relative;
}

// main content
.da-chatbot-content {
  flex-grow: 1;
  overflow-y: auto;
}

// Styling the scrollbar for the chat container
.da-chatbot-content::-webkit-scrollbar {
  width: 7px;
}

// Styling a scrollbar track
.da-chatbot-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

// Styling the scrollbar itself
.da-chatbot-content::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 10px;
}

// Styling the slider on hover
.da-chatbot-content::-webkit-scrollbar-thumb:hover {
  background: #919191;
}

// disclaimer
.da-chatbot-disclaimer {
  font-size: 10px;
  line-height: 13px;
  color: #7d8486;
  text-align: center;
  margin-bottom: 16px;
}

// messages container
.da-chatbot-messages {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

// single message
.da-chatbot-message {
  position: relative;
  margin-bottom: 16px;
  padding: 12px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -16px;
    width: 16px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &--user {
    background-color: #53cff8;
    color: #000;
    align-self: flex-end;

    &:after {
      background-image: url(./da-chatbot-bubble-user.svg);
      right: 0;
    }
  }

  &--bot {
    background-color: #eff1f1;
    color: #000;

    &:after {
      background-image: url(./da-chatbot-bubble-bot.svg);
      left: 0;
    }
  }

  & + .da-chatbot-message {
    margin-top: 8px;
  }

  & > * + * {
    display: block;
    margin-top: 8px !important;
  }

  * {
    font-size: 16px;
    line-height: 20px;
  }

  b,
  strong {
    font-weight: 500;
  }

  a {
    text-decoration: underline;
    color: inherit;
    transition: 0.2s ease-in;

    &:hover,
    &:focus,
    &:active {
      color: inherit;
    }
  }

  ol,
  ul {
    padding: 0;
    list-style-position: inside;

    li {
      & + li {
        margin-top: 8px;
      }
    }
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }
}

// scroll button
.da-chatbot-scroller {
  position: absolute;
  bottom: 80px;
  right: 24px;
  display: inline-flex;
  -webkit-appearance: none;
  box-shadow: none;
  padding: 0;
  border: none;
  outline: none;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  background: #fff url(./da-chatbot-scroll.svg) center no-repeat;
  cursor: pointer;
  z-index: 1;
  transition: 0.2s ease-in;
  opacity: 0;

  &--visible {
    opacity: 1;
  }
}

// section with input and submit button
.da-chatbot-ctrls {
  flex-shrink: 0;
  margin-top: 8px;
  position: relative;
}

// input
.da-chatbot-input {
  width: 100%;
  height: 48px;
  border: 1px solid #ccc;
  padding: 0 48px 0 12px;
  font-family: 'DA_FuturaPT', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #000;

  &::placeholder {
    color: #7d8486;
  }
}

// submit message btn
.da-chatbot-send {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  background: #284086 url(./da-chatbot-send.svg) center no-repeat;
  transition: background-color 0.2s ease-in;
  cursor: pointer;
  border: none;
  -webkit-appearance: none;
  box-shadow: none;

  &:hover {
    background-color: #3453ad;
  }

  &:focus {
    background-color: #213773;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}

// typing animation
.da-chatbot-typing-indicator {
  display: flex;
}

.da-chatbot-typing-dot {
  display: inline-flex;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #000;
  margin: 8px 4px !important;
  opacity: 0;
  animation: blink 3s infinite;
  animation-fill-mode: both;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:last-child {
    animation-delay: 0.4s;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
