.SearchForm {
  background-color: #fff;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-gainsboro);

    @media (min-width: 1200px) {
      left: 373px;
      width: calc(100% - 413px);
    }
  }

  &-Input {
    display: flex;
    position: relative;
    flex-grow: 1;
  }

  &-Dropdown {
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    max-height: 200px;
    background-color: #ffffff;
    padding: 22px 0px;
    overflow-y: auto;
    border-radius: 0 0 2px 2px;

    span {
      cursor: pointer;
      padding: 8px 24px;
      text-decoration: none;
      font-size: 16px;
      line-height: 120%;
      display: flex;
      transition: background-color 0.2s ease-in;

      &:hover {
        background-color: var(--color-control-bg-clear-hover);
      }
    }
  }

  &-Inner {
    position: relative;
    max-width: 808px;
    margin: 0 auto;
    padding: var(--space-2xl);
    display: flex;

    @media (min-width: 768px) {
      padding: var(--space-4xl);
    }

    .Button {
      margin-left: var(--space-xs);
    }
  }
}
