.Loader {
  --backgroundColor: var(
    --loaderBackgroundColor,
    var(--color-control-bg-primary)
  );
  text-align: center;

  img {
    width: var(--loader-size);
    height: var(--loader-size);
  }

  &_size {
    &_s {
      --loader-size: var(--space-xxl);
    }

    &_m {
      --loader-size: var(--space-3xxl);
    }
  }
}

@keyframes loader {
  50% {
    transform: scale(1.5);
  }
}
