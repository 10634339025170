.ErrorPage {
  padding-top: var(--space-7xl);
  position: relative;
  min-height: calc(100vh - var(--header-height));
  padding-bottom: var(--space-3xxl);

  @media screen and (max-width: 767px) {
    padding-top: var(--space-m);
  }

  &-MainTitle,
  &-Title {
    margin-bottom: var(--space-xl);
  }

  &-Button {
    margin-top: var(--space-3xl);
  }

  &-Content {
    margin-top: var(--space-xxl);

    &-Title {
      margin-bottom: var(--space-xl);
    }

    &-Button {
      &:not(:last-child) {
        margin-bottom: var(--space-m);
      }
    }
  }

  &-Dialog {
    max-width: 330px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }

    &-Persons {
      display: flex;
      justify-content: flex-end;

      @media screen and (max-width: 767px) {
        justify-content: space-between;
      }

      &-Item {
        &_right {
          margin-left: 160px;
        }
      }
    }

    &-Chat {
      margin-bottom: var(--space-2xl);
      display: flex;
      flex-flow: column-reverse;
      margin-top: var(--space-5xl);

      &-Item {
        text-align: left;
        margin-left: 32px;
        position: relative;
        min-height: 56px;

        &_right {
          text-align: right;
          margin-right: 24px;
          margin-left: 0;

          @media screen and (max-width: 767px) {
            margin-right: 0;
          }

          > .Tooltip {
            left: auto !important;
            right: 0;
          }
        }

        &:not(:last-child) {
          margin-top: calc(var(--space-xl) + var(--space-m));
        }

        > .Tooltip {
          position: absolute;
          top: 0;
          left: 0;
          white-space: nowrap;
          max-width: auto;
        }
      }
    }
  }

  .BackgroundDecor {
    top: auto;
    bottom: 0;
    max-height: 520px;

    @media screen and (max-width: 1199px) {
      max-height: 552px;
    }

    @media screen and (max-width: 767px) {
      max-height: 221px;
    }
  }
}
