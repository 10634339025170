/* stylelint-disable no-duplicate-selectors */
@use "sass:math";

.VideoCard {
  position: relative;

  &:hover {
    background: var(--color-platinum);

    .VideoCard-Button {
      .Button {
        color: var(--color-blue-primary-light);
      }
    }
  }

  &:active {
    .VideoCard-Photo .Picture {
      opacity: 0.7;
    }

    .VideoCard-Title {
      color: var(--color-blue-primary-dark);
    }

    .VideoCard-Button {
      .Button {
        color: var(--color-blue-primary-dark);
      }
    }
  }

  &_view {
    &_feed {
      &.VideoCard_size {
        &_l {
          --card-photo-space-bottom: var(--space-xl);
          --card-date-space-bottom: var(--space-m);

          @media screen and (max-width: 767px) {
            --card-photo-space-bottom: var(--space-m);
            --card-date-space-bottom: var(--space-2s);
          }
        }

        &_s,
        &_xs {
          --card-photo-space-bottom: var(--space-xl);

          @media screen and (max-width: 767px) {
            --card-photo-space-bottom: var(--space-m);
          }

          .VideoCard-DescriptionSmall {
            margin-top: 0;
          }
        }

        &_s {
          --card-date-space-bottom: var(--space-m);
        }
      }

      &.VideoCard_direction_row {
        --card-photo-space-bottom: 0;

        @media screen and (max-width: 1199px) {
          --card-photo-space-bottom: var(--space-m);
        }

        .VideoCard-Photo {
          width: percentage(math.div(352, 704));

          @media screen and (max-width: 1199px) {
            width: 100%;
          }
        }

        .VideoCard-Body {
          position: relative;
          width: percentage(math.div(328, 704));
          margin-left: auto;

          @media screen and (max-width: 1199px) {
            width: 100%;
          }
        }

        .VideoCard-Date {
          margin-bottom: var(--space-m);
        }

        .VideoCard-Tags {
          margin-top: var(--space-m);

          @media screen and (min-width: 768px) and (max-width: 1199px) {
            margin-top: var(--space-s);
          }
        }

        .VideoCard-Button {
          position: absolute;
          left: 0;
          bottom: 0;

          @media screen and (max-width: 767px) {
            position: relative;
          }
        }
      }
    }

    &_filled {
      &.VideoCard_direction_row {
        --card-photo-space-bottom: 0;

        @media screen and (max-width: 1199px) {
          --card-photo-space-bottom: var(--space-m);
        }

        .VideoCard-Photo {
          width: percentage(math.div(352, 704));

          @media screen and (max-width: 1199px) {
            width: 100%;
          }
        }

        .VideoCard-Body {
          position: relative;
          width: percentage(math.div(328, 704));

          @media screen and (max-width: 1199px) {
            width: 100%;
          }
        }

        .VideoCard-Date {
          margin-bottom: var(--space-m);
        }

        .VideoCard-Tags {
          margin-top: var(--space-m);

          @media screen and (min-width: 768px) and (max-width: 1199px) {
            margin-top: var(--space-s);
          }
        }
      }

      .VideoCard-Button {
        position: absolute;
        left: 0;
        bottom: 0;

        @media screen and (max-width: 767px) {
          /* position: relative; */
        }
      }
    }

    &_text2 {
      --card-photo-space-bottom: var(--space-xl);
      --card-date-space-bottom: var(--space-m);

      @media screen and (max-width: 767px) {
        --card-photo-space-bottom: var(--space-m);
        --card-date-space-bottom: var(--space-2s);
      }
    }
  }

  &-Photo {
    width: 100%;

    .Picture {
      &,
      picture,
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &_direction {
    &_column {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
    }

    &_row {
      display: flex;
      flex-flow: wrap;
    }
  }

  &-Play {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background: #12171a url(../../../assets/img/play.svg) 14px center no-repeat;
  }

  &-Content {
    padding: var(--space-xl);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    border-top: none;
  }

  &-DescriptionSmall {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-m);
  }

  // &-Photo {
  //   &:not(:last-child) {
  //     margin-bottom: var(--card-photo-space-bottom);
  //   }
  // }

  &-Date {
    margin-bottom: var(--card-date-space-bottom);
  }

  &-Link {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &-BottomDescription {
    width: 100%;
    padding-top: var(--space-m);
    border-top: 1px solid var(--color-gainsboro);
  }
}

.VideoCard {
  &_view {
    &_filled {
      height: 100%;
      background: #fff;
      border: 1px solid var(--color-platinum);
      transition: all 0.3s ease-in-out;

      .VideoCard-Photo {
        border-radius: 0;

        .Picture picture {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      .VideoCard-Body {
        display: flex;
        margin-bottom: 52px;
        flex-flow: column;

        @media screen and (max-width: 767px) {
          margin-bottom: 115px;
        }
      }

      .VideoCard-Title {
        > * {
          display: -webkit-box;
          overflow: hidden;
          max-height: 96px;
          white-space: normal;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }

      .VideoCard-Button {
        margin: var(--space-xl);

        @media screen and (max-width: 767px) {
          margin: 96px var(--space-xl) var(--space-xl);
        }
      }
    }

    &_row-filled {
      overflow: hidden;
      background-color: #fff;
      border: 1px solid var(--color-platinum);
      flex-flow: wrap;

      .VideoCard-Photo {
        width: percentage(math.div(288, 808));
        margin-bottom: 0 !important;

        @media screen and (min-width: 768px) and (max-width: 1199px) {
          width: 100%;
          margin-bottom: var(--space-m);
        }

        @media screen and (max-width: 767px) {
          width: 100%;
          margin-bottom: var(--space-m);
        }
      }

      .VideoCard-Body {
        width: percentage(math.div(520, 808));
        margin-left: 0;
        padding: var(--space-xl);

        @media screen and (min-width: 768px) and (max-width: 1199px) {
          width: 100%;
        }

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      .VideoCard-Date {
        margin-bottom: var(--space-m);
      }

      .VideoCard-Tags {
        margin-top: var(--space-m);

        @media screen and (min-width: 768px) and (max-width: 1199px) {
          margin-top: var(--space-s);
        }
      }
    }
  }

  &-Photo {
    position: relative;
    overflow: hidden;
  }

  &-Title {
    margin-top: var(--space-m);
  }

  &-Tags {
    display: flex;
    margin-top: var(--space-m);
    margin-right: calc(var(--space-xs) * -1);
    margin-bottom: calc(var(--space-xs) * -1);
    margin-left: calc(var(--space-xs) * -1);
    flex-flow: wrap;

    @media screen and (min-width: 768px) and (max-width: 1199px) {
      margin-top: var(--space-s);
    }

    @media screen and (max-width: 767px) {
      margin-top: var(--space-2s);
      margin-right: calc(var(--space-2xs) * -1);
      margin-bottom: calc(var(--space-2xs) * -1);
      margin-left: calc(var(--space-2xs) * -1);
    }

    &:first-child {
      margin-top: 0;
    }

    + .VideoCard-Title {
      margin-top: var(--space-xs);
    }

    .VideoCard-Tag {
      display: flex;
      margin: 0 var(--space-xs) var(--space-xs);

      @media screen and (max-width: 767px) {
        margin: 0 var(--space-2xs) var(--space-2xs);
      }
    }
  }

  &-Button {
    z-index: 11;
    padding-top: calc(var(--space-2xl) - 4px);
  }
}

.Modal-Window.VideoCard-Modal {
  overflow: visible;
  max-width: 1016px;
  max-height: 570px;

  @media screen and (max-width: 1199px) {
    max-width: 688px;
    max-height: 386px;
  }

  @media screen and (max-width: 767px) {
    max-width: 288px;
    max-height: 162px;
  }
}
