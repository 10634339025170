.ProjectsCardsSlider {
  .swiper-slide .ProjectCard {
    @media screen and (max-width: 1199px) {
      max-width: 248px;
    }

    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }
}
