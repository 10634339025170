.BadgeCarousel {
  border-radius: 16px;
  background-color: #fff;
  padding: var(--space-3xl);

  &-Carousel {
    overflow-x: hidden;
  }

  &-Slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: var(--space-m);
    min-height: 288px;

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      text-align: center;
    }
  }

  &-Img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 140px;
  }

  &-Caption {
    margin-top: var(--space-m);
    text-align: center;
    &,
    &:hover,
    &:active,
    &:focus {
      color: var(--color-charcoal);
    }
  }
}
